

import Layout from '../components/layout'

import React, { Component } from 'react'

import SubPage from '../components/subPage'
import { Flex, Box } from '@rebass/grid/emotion'
import { Text } from '@rebass/emotion'
import { css } from '@emotion/react'
import SecondFold from './../components/home/SecondFold'

const Content3 = css`
    hyphens: auto;
`


// import "whatwg-fetch";

export default class Impressum extends Component {
    render() {
        return (
            <Layout title="Impressum">

                <SubPage>
                    <Box mx={"auto"} css={{ maxWidth: 1150 }} mb={[50, 100]}>
                        <Flex justifyContent="center">
                            <Box width={1}>
                                <Text color="#6f7c82" fontSize={20} fontWeight={600} textAlign="center">Impressum</Text>
                            </Box>
                        </Flex>
                        <Flex my={[10, 20]} flexDirection="column">
                            <Box my={[20, 40]}>
                                <Text css={Content3} mb={20} color="#32335c" fontWeight={400} fontSize={20} lineHeight={1.7}>
                                    Balkon Systeme Taucha GmbH<br />
                                    Geschäftsführer:	Jan Bregenzer<br />
                                    Eingetragen im:	Amtsgericht Leipzig HRB 18883<br />
                                    USt-ID Nr.:	DE 813407519<br />
                                </Text>
                                <Text css={Content3} mb={20} color="#32335c" fontWeight={400} fontSize={20} lineHeight={1.7}>
                                    Unsere Kontaktdaten:<br />
                                    Balkon Systeme Taucha GmbH<br />
                                    Schillerstraße 86<br />
                                    04425 Taucha<br />
                                    Telefon: <a onClick={() => {
                            if (typeof window !== "undefined" && window.dataLayer) {
                                window.dataLayer.push({
                                    'event': 'contactPhone',
                                    'category': 'contactCategory',
                                    'action': 'call',
                                });
                            }
                            else {
                                console.error("data layer not found")
                            }            
                        }} href="tel:493429898890">034298 - 98 89 0</a><br />
                                    Telefax: 034298 - 98 89 20<br />
                                    e-mail: <a onClick={() => {
                                if (typeof window !== "undefined" && window.dataLayer) {
                                    window.dataLayer.push({
                                        'event': 'contactEmail',
                                        'category': 'contactCategory',
                                        'action': 'email',
                                    });
                                }
                                else {
                                    console.error("data layer not found")
                                }                
                            }} href="mailto:info@balkonsysteme.info">info@balkonsysteme.info</a><br />
                                    Rufen sie uns an oder senden uns eine e-mail. Wir erstellen Ihnen ein unverbindliches und kostenfreies Angebot.<br />
                                </Text>
                            </Box>
                        </Flex>
                    </Box>

                    <SecondFold />
                </SubPage>
            </Layout>

        )
    }
}
